import React, { useEffect, useState } from 'react'
import BannerTop from '../../Partials/Section/BannerTop/BannerTop'
import banner from '../../../Assets/images/banner/aboutUs.png'
import Ceo from './Ceo/Ceo'
import Director from './Director/Director'
import './AboutUs.css'
import CompanyOMV from './CompanyOMV/CompanyOMV'
import Header from '../../Partials/Section/Header/Header'
import axios from 'axios'
import { ABOUTS_API, ABOUT_CORES_API } from '../../../Utilities/APIs'
function AboutUs() {
    const [abouts, setAbouts] = useState([])
    const [aboutCores, setAboutCores] = useState([])
    useEffect(() => {
        async function getAbouts() {
            const { data } = await axios.get(ABOUTS_API)
            setAbouts(data)
        }
        getAbouts()

    }, [])

    useEffect(() => {
        async function getAbouts() {
            const { data } = await axios.get(ABOUT_CORES_API)
            setAboutCores(data)
        }
        getAbouts()

    }, [])
    return (
        <>
            <div className='home_banner_nav'>
                <Header />
                <BannerTop
                    image={banner}
                    title='About Us'
                    subTitle='We are here to help you unite!'
                />
            </div>

            <div className="about_us">
                <div className="container">
                    <div className="title_wrapper">
                        <p className="desc ">
                            {abouts[0]?.description}
                        </p>
                    </div>
                </div>
            </div>
            <CompanyOMV />
            <Ceo aboutCores={aboutCores}/>
            <Director aboutCores={aboutCores} />
        </>
    )
}

export default AboutUs
import React, { useEffect, useState } from 'react'
import BannerTop from '../../Partials/Section/BannerTop/BannerTop'
import banner from '../../../Assets/images/banner/NecessaryLink.png'
import link from '../../../Assets/images/WorkingRemotely.png'
import './NecessaryLink.css'
import Header from '../../Partials/Section/Header/Header'
import { NECESSARY_LINKS_API } from '../../../Utilities/APIs'
import axios from 'axios'
import { Link } from 'react-router-dom'
function NecessaryLink() {
    const [necessaryLinks, setNecessaryLinks] = useState([])
    useEffect(() => {
        async function getNecesssaryLinks() {
            const { data } = await axios.get(NECESSARY_LINKS_API)
            setNecessaryLinks(data)
        }
        getNecesssaryLinks()

    }, [])
    return (
        <>
          <div className='home_banner_nav'>
                <Header />
                <BannerTop
                image={banner}
                title='Necessary Links'
                subTitle='Financial help starts from here...'/>
            </div>
            
            <div className="necessary_link ">
                <div className="container">
                    <div className="title_wrapper">
                        <h2 className="title">Necessary Links</h2>
                        <p className="desc ">
                            Please Click On These Links To Find More Information Regarding Local Laws And Regulations.
                            We Thank You For Visiting Our Website And Will Be Happy To Answer Any Queries That You May Have.
                        </p>
                    </div>
                </div>
                <div className="necessary_link_content container">
                    <div className="necessary_links">
                        {
                            necessaryLinks?.map((necessaryLink, index) => 

                        <div className="links">
                            <h3>{index +1 }</h3>
                           <Link to={necessaryLink.link}> <p>{necessaryLink.name}</p></Link>
                        </div>
                                )
                        }
                       
                    </div>
                    <div className="link_img">
                        <img src={link} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NecessaryLink
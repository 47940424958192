import React from 'react'
import Slider from './Slider/Slider';
import './OurServices.css';

function OurServices() {
  return (
    <section className="our-service">
      <div className="container">
        <div className="title_wrapper ">
          <h2 className="title center">Our Services</h2>
          <p className="desc center">
            We will bring the breathe of our experience and industry knowledge to help you succeed
          </p>
        </div>
      </div>
      <Slider />
    </section>
  )
}

export default OurServices
import React, { useEffect, useState } from 'react'
import BannerTop from '../../Partials/Section/BannerTop/BannerTop'
import banner from '../../../Assets/images/banner/career.png'
import arrow from '../../../Assets/images/icons/arrow.png'
import './Career.css'
import Header from '../../Partials/Section/Header/Header'
import axios from 'axios'
import { CAREERS_API } from '../../../Utilities/APIs'
function Career() {
    const [careers, setCareers] = useState([])
    useEffect(() => {
        async function getCareers() {
            const { data } = await axios.get(CAREERS_API)
            setCareers(data)
        }
        getCareers()

    }, [])
    return (
        <>
            <div className='home_banner_nav'>
                <Header />
                <BannerTop
                    image={banner}
                    title='Careers'
                    subTitle='Start your career from here...'
                />
            </div>

            <div className="container">

                <div className="careers">
                    {
                        careers?.map(career =>
                            <div className="career_wrapper" key={career._id}>
                                <div className="circular_apply">
                                    <div className="circular">
                                        <h1>{career.name}</h1>
                                        <h3>{career.description}</h3>
                                        <div className="circular_info">
                                            <p className='job_time'>Job Type: Full time</p>
                                            <p className='vacancies'>Vacancies: 1</p>
                                            <p>Deadline: January 15, 2023 (8 days left)</p>
                                        </div>
                                    </div>
                                    <div className="apply">
                                        <p>Apply
                                            <img src={arrow} alt="" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                </div>
            </div>
        </>
    )
}

export default Career
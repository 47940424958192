import React from 'react'
import BannerTop from '../../Partials/Section/BannerTop/BannerTop'
import banner from '../../../Assets/images/banner/services.png'
import FinanceAndTrade from './FinanceAndTrade/FinanceAndTrade'
import Header from '../../Partials/Section/Header/Header'
function Services() {
    return (
        <>
            <div className='home_banner_nav'>
                <Header />
                <BannerTop
                    image={banner}
                    title='Our Services'
                    subTitle='Finance and Trade, Audit, Company Set up and Licensing, Real Estate...'

                />
            </div>

            <FinanceAndTrade />
          
        </>
    )
}

export default Services

import React from 'react'
import {  IMAGE_URL } from '../../../../Utilities/APIs'
import './Ceo.css'
function Ceo({aboutCores}) {
  
    return (
        <div className="ceo">
            <div className="container">
                <div className="ceo_info">
                    <div className="ceo_details">
                        <h1>{aboutCores[0]?.name}</h1>
                        <h3>CEO</h3>
                        <p>{aboutCores[0]?.description}</p>
                    </div>
                    <div className="ceo_img">
                        <div className="pic">
                            <img src={IMAGE_URL + aboutCores[0]?.image} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ceo
import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import banner from '../../../../Assets/images/banner/home-banner.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Banner.css";

// import required modules
import { Autoplay } from "swiper";
import { IMAGE_URL, SLIDER_API } from "../../../../Utilities/APIs";
import axios from "axios";


function Banner() {
    const [sliders, setSliders] = useState([])
    useEffect(() => {
        async function getSliders() {
            const { data } = await axios.get(SLIDER_API)
            setSliders(data)
        }
        getSliders()

    }, [])

    


    return (
        <div className="home_banner">
            <Swiper
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                loop={true}
               
                modules={[Autoplay]}
                className="mySwiper"
            >
             {
                sliders?.map(slider => 
                    <SwiperSlide key={slider._id}>
                    <div className="swiper-slide banner" >
                        <img src={IMAGE_URL + slider.image} alt="" />
                        <div className="hero-container homeContent">
                            <div className="container">
                                <h1 className="heroTitle">
                                    {slider.name}
                                </h1>
                                <p className="banner_desc">
                                {slider.subTitle}
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                    )
             }
               

            </Swiper>
        </div>
    )
}

export default Banner
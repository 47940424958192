import { backendUrl, spacesBucketUrl } from "../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'

export const EMPLOYEES_API = API_URL + 'employees/'
export const DESIGNATIONS_API = API_URL + 'designations/'
export const EMPLOYEE_DESIGNATIONS_API = API_URL + 'employeeDesignations/'


export const APP_SETTINGS_API = API_URL + 'appSettings/'
export const SLIDER_API = API_URL + 'sliders/'
export const ABOUT_CORES_API = API_URL + 'aboutCores/'
export const CORE_VALUES_API = API_URL + 'coreValues/'
export const CLIENTS_API = API_URL + 'clients/'
export const ABOUTS_API = API_URL + 'abouts/'
export const SERVICE_TYPES_API = API_URL + 'serviceTypes/'
export const SERVICES_API = API_URL + 'services/'
export const SISTER_CONCERN_TYPES_API = API_URL + 'sisterConcernTypes/'
export const SISTER_CONCERNS_API = API_URL + 'sisterConcerns/'
export const COMPANY_OVERVIEWS_API = API_URL + 'companyOverviews/'
export const MISSION_API = API_URL + 'missions/'
export const VISSION_API = API_URL + 'visions/'
export const CAREERS_API = API_URL + 'careers/'
export const NECESSARY_LINKS_API = API_URL + 'necessaryLinks/'
export const TESTIMONIALS_API = API_URL + 'testimonials/'
export const CONTACTS_API = API_URL + 'contacts/'
export const TAGS_API = API_URL + 'tags/'



import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import sliderImg from '../../../../../Assets/images/slider-1.png';
import './Slider.css'
import { IMAGE_URL, SERVICES_API } from '../../../../../Utilities/APIs';
import axios from 'axios';
 
function Slider() {
  const [otherServices, setOtherServices] = useState(null)
   
    useEffect(() => {
        async function getFinanceTrades() {
            const { data } = await axios.get(SERVICES_API + 'populated')
            const others = data.filter(data => data.serviceType.name !== "FINANCE AND TRADE")
            
            setOtherServices(others)
        }
        getFinanceTrades()

    }, [])
  return (
    <div className="sliders">
      <div className="swiper-container container">
        <div className="service_navigate">
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
        <Swiper
            slidesPerView={1}
            spaceBetween={30}
            breakpoints={{
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
            }}
            freeMode={true}
            navigation={{

              prevEl: `.service_navigate .swiper-button-prev`,
              nextEl: `.service_navigate .swiper-button-next`
            }}
            modules={[FreeMode, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="slider-content">
                <div className="slider-images">
                  <img src={sliderImg} alt="" />
                </div>
              </div>
              <div className="slider-details">
                <h3>Finance And Trade</h3>
                <p>We provide a complete spectrum of financial and management consultancy services to meet a company’s need through our specialized knowledge and expertise.</p>
              </div>
            </SwiperSlide>
            {
              otherServices?.map(otherService => 
                
            <SwiperSlide>
              <div className="slider-content">
                <div className="slider-images">
                  <img src={IMAGE_URL + otherService.image} alt="" />
                </div>
              </div>
              <div className="slider-details">
                <h3>{otherService.name}</h3>
                <p>{otherService.description}</p>
              </div>
            </SwiperSlide>
                )
            }
           

          </Swiper>
      </div>
    </div>
  )
}

export default Slider
import React from 'react'
import { IMAGE_URL } from '../../../../Utilities/APIs'
function Director({aboutCores}) {
    return (
        <div className="ceo">
            <div className="container director">
                <div className="ceo_info">
                    <div className="ceo_img">
                        <div className="pic">
                            <img src={IMAGE_URL + aboutCores[1]?.image} alt="" />
                        </div>
                    </div>
                    <div className="ceo_details padding_left">
                        <h1>{aboutCores[1]?.name}</h1>
                        <h3>Director</h3>
                        <p>{aboutCores[1]?.description}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Director
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { CLIENTS_API, IMAGE_URL } from '../../../../Utilities/APIs'
import './OurClients.css'
function OurClients() {
    const [clients, setClients] = useState([])
    useEffect(() => {
        async function getClients() {
            const { data } = await axios.get(CLIENTS_API)
            setClients(data)
        }
        getClients()

    }, [])
    return (
        <div className="our_clients">
            <div className="container">
                <div className="our_clients_content_wrapper">
                    <div className="client_title">
                        <div className="title_wrapper ">
                            <h2 className="title">Our Clients</h2>
                            <p className="desc ">
                                SOME OF OUR VALUED CLIENTS
                            </p>
                        </div>
                    </div>
                    <div className='logos'>
                        {
                            clients?.map(client =>
                                <div className="client_logo">
                                    <img src={IMAGE_URL + client.image} alt="" />
                                </div>

                            )
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export default OurClients
import React, { useEffect, useState } from 'react'
import './FinanceAndTrade.css'
import axios from 'axios'
import { IMAGE_URL, SERVICES_API } from '../../../../Utilities/APIs'
import CompanyLicence from '../CompanyLicence/CompanyLicence'
function FinanceAndTrade() {
    const [financeTrades, setFinaceTrades] = useState(null)
    const [otherServices, setOtherServices] = useState(null)
   

    useEffect(() => {
        async function getFinanceTrades() {
            const { data } = await axios.get(SERVICES_API + 'populated')
            const finTrade = data.filter(data => data.serviceType.name === "FINANCE AND TRADE")
            const others = data.filter(data => data.serviceType.name !== "FINANCE AND TRADE")
            setFinaceTrades(finTrade)
            setOtherServices(others)
        }
        getFinanceTrades()

    }, [])
    return (
        <div>

            <div className="finance_trade">
                <div className="container">
                    <div className="finance_trade_wrapper ">
                        <div className="title_wrapper">
                            <h2 className="title center">Finance And Trade</h2>
                        </div>
                        <div className="finance_trade_content">
                            {
                                financeTrades?.map(financeTrade =>
                                    <div className="service">
                                        <div className="service_img">
                                            <img src={IMAGE_URL + financeTrade.image} alt="" />
                                        </div>
                                        <h1>{financeTrade.name}</h1>
                                        <p>{financeTrade.description}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <CompanyLicence  otherServices={otherServices}/>
        </div>
    )
}

export default FinanceAndTrade
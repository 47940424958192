import React from 'react';
import './OurLocation.css'

function OurLocation() {
    return (
        <section className="location_map">
            <div className="location_map_wrapper">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.870159787741!2d90.41442531434912!3d23.78763749327107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7a1f20270c5%3A0x4eaca251ffef5956!2sSource%20and%20Solution!5e0!3m2!1sen!2sbd!4v1679380156816!5m2!1sen!2sbd" title='1'></iframe>
            </div>
        </section>
    )
}

export default OurLocation
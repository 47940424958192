import React, { useEffect, useState } from 'react'
import BannerTop from '../../Partials/Section/BannerTop/BannerTop'
import banner from '../../../Assets/images/banner/sister.png'
import concern from '../../../Assets/images/concern.png'
import './SisterConcern.css'
import Header from '../../Partials/Section/Header/Header'
import { SISTER_CONCERNS_API } from '../../../Utilities/APIs'
import axios from 'axios'
function SisterConcern() {
    const [sisterConcers, setSisterConcerns] = useState([])
    console.log(sisterConcers);
    useEffect(() => {
        async function getSisterConcers() {
            const { data } = await axios.get(SISTER_CONCERNS_API)
            setSisterConcerns(data)
        }
        getSisterConcers()

    }, [])
    return (
        <>
            <div className='home_banner_nav'>
                <Header />
                <BannerTop
                    image={banner}
                    title='Sister Concern'
                    subTitle='Start your journey from here....'
                />
            </div>

            <div className="sister_concern container">
                <div className="sister_concern_img">
                    <img src={concern} alt="" />
                </div>
                <div className="sister_concern_content">
                    <div className="title_wrapper">
                        <h2 className="center">{sisterConcers[0]?.name}</h2>
                        <p>
                            <span className="sg_lanka"> {sisterConcers[0]?.description.slice(0, 20)} </span>
                            {sisterConcers[0]?.description.slice(20, 358)} <br />
                            {sisterConcers[0]?.description.slice(358)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SisterConcern
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";

import './CompanyLicence.css'
import { IMAGE_URL } from '../../../../Utilities/APIs';
function CompanyLicence({ otherServices }) {
    return (
        <div className="company_licence">
            <div className="title_wrapper">
                <h2 className="title center">Other Services</h2>
            </div>
            <div className="company_licence_wrapper">
                <div className="company_licence_navigate">
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    breakpoints={{
                        576: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                    }}
                    freeMode={true}
                    navigation={{

                        prevEl: `.company_licence_navigate .swiper-button-prev`,
                        nextEl: `.company_licence_navigate .swiper-button-next`
                    }}
                    modules={[FreeMode, Navigation]}
                    className="mySwiper"
                >
                    {
                        otherServices?.map(otherService =>
                            <SwiperSlide>
                                <div className="slider-content">
                                    <div className="company_licence-images">
                                        <img src={IMAGE_URL + otherService.image} alt="" />
                                        <div className="company_licence-details">
                                            <h3>{otherService.name}</h3>
                                            <p>{otherService.description.slice(0, 100)}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default CompanyLicence
import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../../Assets/images/logos/logo.png";
// import search from "../../../../Assets/images/icons/search.png";
import { AiOutlineMenu } from "@react-icons/all-files/ai/AiOutlineMenu";
import { RiCloseFill } from "@react-icons/all-files/ri/RiCloseFill";
import "./Header.css";
function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="" />
        </Link>

        <div className={`desktopNav nav-elements active}`}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/aboutUs">
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/services">
                Our Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/necessaryLink">
                Necessary Links
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/sisterConcern">
                Sister Concern
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/careers">
                Careers
              </NavLink>
            </li>
            <li className="nav-item">
              <Link className=" btn" to="/contactUs">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="menu_search">
          {/* <div class="search-box">
            <button class="btn-search">
            <img src={search} alt="" />
            </button>
            <input
              type="text"
              class="input-search"
             
            />
          </div> */}

          {/* <ul className="navbar-nav ">
          <li className="nav-item">
          </li>
        </ul> */}
          <button
            onClick={handleMobileMenu}
            className="open_menu"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sideCanvas"
          >
            {/* <img src= alt="" /> */}
            <AiOutlineMenu />
          </button>
        </div>
      </div>
      {mobileMenu && (
        <div
          data-aos="fade-right"
          className="offcanvas offcanvas-start"
          tabindex="-1"
          id="sideCanvas"
        >
          <div className="offcanvas-header">
            <div className="mobile_logo">
              <img src={logo} alt="" />
            </div>
            <button
              onClick={handleMobileMenu}
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              className="close_menu"
            >
              <RiCloseFill />
            </button>
          </div>
          <div className="offcanvas-body">
            <ul className="sideMenu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/aboutUs">About Us</Link>
              </li>
              <li>
                <Link to="/services">Our Services</Link>
              </li>
              <li>
                <Link to="/necessaryLink">Necessary Links</Link>
              </li>

              <li>
                <Link to="/sisterConcern"> Sister Concern</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              <li>
                <Link to="/contactUs">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import icon from "../../../../Assets/images/icons/frame.png";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import overviewLogo from '../../../../Assets/images/icons/over.png'
import overviewLogo1 from '../../../../Assets/images/icons/ovr.png'
import missionActive from '../../../../Assets/images/icons/mission_active.png'
import missionDefault from '../../../../Assets/images/icons/mission_default.png'
import visionDefault from '../../../../Assets/images/icons/vision_default.png'
import visionActive from '../../../../Assets/images/icons/vision_active.png'
import axios from "axios";
import { COMPANY_OVERVIEWS_API, MISSION_API, VISSION_API } from "../../../../Utilities/APIs";
import "./CompanyOMV.css";
function CompanyOMV() {
  const [selectedTab, setSelectedTab] = useState("tab1");


  const [companyOverviews, setCompanyOverviews] = useState([])
  const [missions, setMissions] = useState([])
  const [vissions, setVissions] = useState([])

  const [overview, setOverview] = useState(false)
  const [mission, setMission] = useState(false)
  const [vission, setVission] = useState(false)

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleOverview = () => {
    setOverview(!overview)
  }
  const handleMission = () => {
    setMission(!mission)
  }
  const handleVission = () => {
    setVission(!vission)
  }

  useEffect(() => {
    async function getCompanyOverviews() {
      const { data } = await axios.get(COMPANY_OVERVIEWS_API)
      setCompanyOverviews(data)
    }
    getCompanyOverviews()

  }, [])

  useEffect(() => {
    async function getMissions() {
      const { data } = await axios.get(MISSION_API)
      setMissions(data)
    }
    getMissions()

  }, [])

  useEffect(() => {
    async function getVision() {
      const { data } = await axios.get(VISSION_API)
      setVissions(data)
    }
    getVision()

  }, [])
  return (
    <div className="company_overview">
      <div className="container">
        <div className="omv_logo">
          <div onClick={() => handleTabClick("tab1")} className="logo_card">
            <div className={selectedTab === "tab1" ? "svg_fill" : 'svg_fill_none'}>
              {
                selectedTab === "tab1" ?
                  <img src={overviewLogo1} alt="" /> :
                  <img src={overviewLogo} alt="" />
              }

            </div>
            <p>Overview</p>
          </div>
          <div onClick={() => handleTabClick("tab2")} className="logo_card">
            <div className={selectedTab === "tab2" ? "mission_svg_fill" : 'mission_svg_fill_none'}>
              {
                selectedTab === "tab2" ?
                  <img src={missionActive} alt="" /> :
                  <img src={missionDefault} alt="" />
              }

            </div>
            <p>Mission</p>
          </div>
          <div onClick={() => handleTabClick("tab3")} className="logo_card">
            <div className={selectedTab === "tab3" ? "vission_svg_fill" : 'vission_svg_fill_none'}>
              {
                selectedTab === "tab3" ?
                  <img src={visionActive} alt="" /> :
                  <img src={visionDefault} alt="" />
              }

            </div>
            <p>Vision</p>
          </div>
        </div>
        <div className="omv_wrapper">
          {/* <div className="omv_title">
            <h1
              className={selectedTab === "tab1" && "active"}
           
            >
              {companyOverviews[0]?.name.slice(0, 8)}
              <span className={selectedTab === "tab1" && "green_color"}>
                {companyOverviews[0]?.name.slice(8)}
              </span>

            </h1>
            <h1
              className={selectedTab === "tab2" && "active"}
             
            >
              {missions[0]?.name.slice(0, 8)}
              <span className={selectedTab === "tab2" && "green_color"}>
                {missions[0]?.name.slice(8)}
              </span>
            </h1>
            <h1
              className={selectedTab === "tab3" && "active"}
            
            >
              {vissions[0]?.name.slice(0, 8)}
              <span className={selectedTab === "tab3" && "green_color"}>
                {vissions[0]?.name.slice(8)}
              </span>
            </h1>
          </div> */}
          <div className="omv_description">
            {selectedTab === "tab1" && (
              <div className="omv">
                <img src={icon} alt="" />
                <h1
                  className={selectedTab === "tab1" && "active"}

                >
                  {companyOverviews[0]?.name.slice(0, 8)}
                  <span className={selectedTab === "tab1" && "green_color"}>
                    {companyOverviews[0]?.name.slice(8)}
                  </span>

                </h1>
                <p>
                  {companyOverviews[0]?.description}
                </p>
                <div className="omv_icon">
                  <img src={icon} alt="" />
                </div>
              </div>
            )}
            {selectedTab === "tab2" && (
              <div className="omv">
                <img src={icon} alt="" />
                <h1
                  className={selectedTab === "tab2" && "active"}
                  onClick={() => handleTabClick("tab2")}
                >
                  {missions[0]?.name.slice(0, 8)}
                  <span className={selectedTab === "tab2" && "green_color"}>
                    {missions[0]?.name.slice(8)}
                  </span>
                </h1>
                <p>
                  {missions[0]?.description}
                </p>
                <div className="omv_icon">
                  <img src={icon} alt="" />
                </div>
              </div>
            )}
            {selectedTab === "tab3" && (
              <div className="omv">
                <img src={icon} alt="" />
                <h1
                  className={selectedTab === "tab3" && "active"}
                  onClick={() => handleTabClick("tab3")}
                >
                  {vissions[0]?.name.slice(0, 8)}
                  <span className={selectedTab === "tab3" && "green_color"}>
                    {vissions[0]?.name.slice(8)}
                  </span>
                </h1>
                <p>
                  {vissions[0]?.description}
                </p>
                <div className="omv_icon">
                  <img src={icon} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mobile_omv">
          <div className="mobile_omv_content">
            <div className="title_icon" onClick={handleOverview}>
              <h1 className={overview && 'active'} >Company <span className={overview && 'green_color'}> Overview</span> </h1>
              {overview ? <h3 className="up"><BsChevronUp /></h3> :
                <h3 ><BsChevronDown /></h3>
              }
            </div>
            {overview &&
              <p>  {companyOverviews[0]?.description}</p>
            }
          </div>
          <div className="mobile_omv_content">
            <div className="title_icon" onClick={handleMission}>
              <h1 className={mission && 'active'} >Company <span
                className={mission && 'green_color'}> Mission</span> </h1>
              {mission ? <h3 className="up"><BsChevronUp /></h3> :
                <h3 ><BsChevronDown /></h3>
              }
            </div>
            {mission &&
              <p> {missions[0]?.description}</p>
            }
          </div>
          <div className="mobile_omv_content">
            <div className="title_icon" onClick={handleVission}>
              <h1 className={vission && 'active'} >Company <span
                className={vission && 'green_color'}> Vision</span> </h1>
              {vission ? <h3 className="up"><BsChevronUp /></h3> :
                <h3 ><BsChevronDown /></h3>
              }
            </div>
            {vission &&
              <p>  {vissions[0]?.description}</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyOMV;

import React, { useState } from "react";
import contact from "../../../../Assets/images/Rectangle.png";
import "./ContactForm.css";
function ContactForm() {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')


  const handleSubmitForm = (e) => {
    e.preventDefault();

    const contactMessages = {
      name,
      phone,
      email,
      subject,
      message,

    };
    console.log(contactMessages);
    // axios.post(, contactMessages).then((res) => {
    //   setInformation(res.data);
    // });
    e.target.reset()
  };
  return (
    <div className="contact_form">
      <div className="container">
        <div></div>
        <div className="contact_form_wrapper">
          <div className="form_title">
            <img src={contact} alt="" />
            <div className="form_content">
              <h1>Schedule a Free Discovery Session</h1>
              <p>Call Us at +8801711538701</p>
            </div>
          </div>
          <div className="form_wrapper">
            <form onSubmit={handleSubmitForm}>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="number"
                id="phone"
                name="phone"
                placeholder="Your phone"
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your e-mail address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}

              />
              <textarea
                type="text"
                id="message"
                name="message"
                placeholder="Drop us a message here…"
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="form_btn">
                <button type="submit" className="btn">
                  Send Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;

import React from 'react'
import Header from '../../Partials/Section/Header/Header'
import Banner from './Banner/Banner'
import ContactForm from './ContactForm/ContactForm'
import ExpandBusiness from './ExpandBusiness/ExpandBusiness'
import OurClients from './OurClients/OurClients'
import OurServices from './OurServices/OurServices'
// import YourBusiness from './YourBusiness/YourBusiness'
import './Home.css'
function Home() {
  return (
    <div>
      <div className='home_banner_nav'>
        <Header />
        <Banner />
      </div>
      <ExpandBusiness />
      <OurServices />
      {/* <YourBusiness /> */}
      <OurClients />
      <ContactForm />
    </div>
  )
}

export default Home
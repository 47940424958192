import React from "react";
import email from '../../../../Assets/images/icons/email.png'
import phone from '../../../../Assets/images/icons/phone.png'
import "./Footer.css";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
    <div className="container">
        <div className="footer_content">
            <div className="footer_left">
                <p><img src={phone} alt="" /> +88 02 226602288</p>
            </div>
            <div className="footer_center">
            <p><img src={email} alt="" /> info@sourceandsolutionbd.com</p>
               
            </div>
            <div className="footer_right">
                <p>Made with ♡ by <Link to="https://theantopolis.com/" className="antopolis">Antopolis</Link></p>
            </div>
        </div>
    </div>
</div>
  );
}

export default Footer;

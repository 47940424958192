import React, { useEffect, useState } from 'react'
import BannerTop from '../../Partials/Section/BannerTop/BannerTop'
import banner from '../../../Assets/images/banner/contact.png'
import './ContactUs.css'
import OurLocation from './OurLocation/OurLocation'
import Header from '../../Partials/Section/Header/Header'
import axios from 'axios'
import { CONTACTS_API } from '../../../Utilities/APIs'
function ContactUs() {
    const [contacts, setContacts] = useState([])

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')


    const handleSubmitForm = (e) => {
        e.preventDefault();

        const contactMessages = {
            name,
            phone,
            email,
            subject,
            message,

        };
        console.log(contactMessages);
        // axios.post(, contactMessages).then((res) => {
        //   setInformation(res.data);
        // });
        e.target.reset()
    };
    useEffect(() => {
        async function getContacts() {
            const { data } = await axios.get(CONTACTS_API)
            setContacts(data)
        }
        getContacts()

    }, [])
    return (
        <>
            <div className='home_banner_nav'>
                <Header />
                <BannerTop
                    image={banner}
                    title='Contact Us'
                    subTitle='We are here to help you unite!'
                />
            </div>

            <div className="contact_us">
                <div className="container">
                    <div className="contact_us_content">
                        <div className="contact_us_info">

                            <div className='address details'>
                                <h3 className="phone">PHONE</h3>
                                <p>{contacts[0]?.phone}</p>
                            </div>
                            <div className="address details">
                                <h3 className="phone">EMAIL</h3>
                                <p>{contacts[0]?.email}</p>
                            </div>
                            <div className="address ">
                                <h3 className="phone">ADDRESS</h3>
                                <p>{contacts[0]?.address}</p>
                            </div>
                        </div>
                        <div className="contact_us_form">
                            <h1>Let’s Talk About Your Business</h1>
                            <form onSubmit={handleSubmitForm}>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)}

                                />
                                <input
                                    type="number"
                                    id="phone"
                                    name="phone"
                                    placeholder="Your phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}

                                />
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    placeholder="Subject"
                                    onChange={(e) => setSubject(e.target.value)}

                                />
                                <textarea
                                    type="text"
                                    id="message"
                                    name="message"
                                    placeholder="Type your message"
                                    onChange={(e) => setMessage(e.target.value)}

                                />
                                <div className="contact_form_btn">
                                    <button type="submit" className="btn">
                                        Send Now
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <OurLocation />
        </>
    )
}

export default ContactUs
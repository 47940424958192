import React from 'react'
import collaborate from '../../../../Assets/images/icons/collaborate.png'
import art from '../../../../Assets/images/icons/art-and-design.png'
import excellent from '../../../../Assets/images/icons/excellent.png'
import accountability from '../../../../Assets/images/icons/accountability.png'
import team from '../../../../Assets/images/icons/team.png'

import './ExpandBusiness.css'
import { Link } from 'react-router-dom'
function ExpandBusiness() {
  return (
    <>
      <div className="container">
        <div className="expand-business-content">
          <div className="expand-business-details">
            <h2 className="title">The Ideal Place To Expand Your Business In Bangladesh</h2>
            <p className='desc'>The world is moving towards the lights of modernization and development. The role models behind this rapid development are 'You’ - who are always searching to thrive forward and to give the best to this world. We are here to provide endless support to ‘You’ to operate ‘Your business’ smoothly and effortlessly.</p>
            <Link to='/aboutUs' className="btn">
              Learn More
            </Link>
          </div>
          <div className="expand-business-icons">
            <div className="expand">
              <div className="practical business">
              <img src={art} alt="" />
              </div>
              <p>Practical</p>
            </div>
            <div className=" expand">
              <div className="respect business">
                <img src={collaborate} alt="" />
              </div>
              <p>Respect</p>
            </div>
            <div className="expand">
              <div className="excellence business">
              <img src={excellent} alt="" />
              </div>
              <p>Excellence </p>
            </div>
            <div className="expand">
              <div className="accountability business">
              <img src={accountability} alt="" />
              </div>
              <p>Accountability </p>
            </div>
            <div className="expand">
              <div className="team-work business">
              <img src={team} alt="" />
              </div>
              <p>Team Work</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpandBusiness
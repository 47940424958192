import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Partials/Section/Footer/Footer";
import AboutUs from "./Screens/AboutUs/AboutUs";
import Career from "./Screens/Career/Career";
import ContactUs from "./Screens/ContactUs/ContactUs";
import Home from "./Screens/Home/Home";
import NecessaryLink from "./Screens/NecessaryLink/NecessaryLink";
import Services from "./Screens/Services/Services";
import SisterConcern from "./Screens/SisterConcern/SisterConcern";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      {/* <Header/> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/necessaryLink" element={<NecessaryLink />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/sisterConcern" element={<SisterConcern />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/contactUs" element={<ContactUs />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
